import { Routes, UrlMatcher, UrlMatchResult, UrlSegment } from '@angular/router';
import { localeRedirect } from './guards/locale-redirect.guard';
import { isDevMode } from '@angular/core';
import { CheckoutGuard } from './guards/checkout.guard';
import { isNotLoggedInGuard } from './guards/is-not-logged-in.guard';
import { accountGuard } from './guards/account.guard';

export type RouteKey =
  | 'cart'
  | 'stores'
  | 'checkout'
  | 'staticPages'
  | 'search'
  | 'terms'
  | 'privacyPolicy'
  | 'login'
  | 'register'
  | 'yesCampaign';

export const ROUTE_TRANSLATIONS: Record<string, Record<RouteKey, string> | undefined> = {
  en: {
    cart: 'cart',
    stores: 'stores',
    checkout: 'checkout',
    staticPages: 'pages',
    search: 'search',
    terms: 'pages/terms-and-conditions',
    privacyPolicy: 'pages/privacy-policy',
    login: 'login',
    register: 'register',
    yesCampaign: 'yes-campaign',
  },
  cs: {
    cart: 'kosík',
    stores: 'obchody',
    checkout: 'pokladna',
    staticPages: 'stranky',
    search: 'vyhledavani',
    terms: 'stranky/obchodni-podminky',
    privacyPolicy: 'stranky/zasady-ochrany-osobnich-udaju',
    login: 'prihlaseni',
    register: 'registrace',
    yesCampaign: 'kampane-yes',
  },
  sk: {
    cart: 'kosík',
    stores: 'obchodoch',
    checkout: 'pokladna',
    staticPages: 'stranky',
    search: 'vyhladavanie',
    terms: 'stranky/obchodne-podmienky',
    privacyPolicy: 'stranky/politika-ochrany-osobnych-udajov-suborov-cookies-sk',
    login: 'prihlasenie',
    register: 'registracia',
    yesCampaign: 'kampan-yes',
  },
};

const langMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  return segments.length === 0 || segments[0].path.length !== 2
    ? { consumed: [] }
    : { consumed: segments.slice(0, 1) };
};

const isTaxonPath = (segments: UrlSegment[]): UrlMatchResult | null => {
  return segments.length > 0 && segments[0].path === 't' ? { consumed: segments } : null;
};

const isTranslatedUrl = (routeKey: RouteKey): UrlMatcher => {
  return (segments, { segments: [{ path: locale }] }) => {
    return segments.length > 0 &&
      segments[0].path === ROUTE_TRANSLATIONS[locale]?.[routeKey]
      ? { consumed: segments.slice(0, 1) }
      : null;
  };
};

const baseRoutes: Routes = [
  {
    matcher: langMatcher,
    canActivate: [localeRedirect],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },

      {
        matcher: isTranslatedUrl('cart'),
        loadComponent: () =>
          import('./pages/cart-page/cart-page.component').then(
            (m) => m.CartPageComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('search'),
        loadComponent: () =>
          import('./pages/product/product-list/product-list.component').then(
            (m) => m.ProductListComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('stores'),
        loadComponent: () =>
          import('./pages/stores/stores.component').then((m) => m.StoresComponent),
      },
      {
        path: 'panel',
        canActivate: [accountGuard],
        loadComponent: () =>
          import('./pages/customer/customer.component').then((m) => m.CustomerComponent),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/customer/customer/customer.component').then(
                (m) => m.CustomerComponent,
              ),
          },
          {
            path: 'addresses',
            loadComponent: () =>
              import('./pages/customer/address/address.component').then(
                (m) => m.AddressComponent,
              ),
          },
          {
            path: 'addresses/edit/:type',
            loadComponent: () =>
              import('./pages/customer/address/edit-address/edit-address.component').then(
                (m) => m.EditAddressComponent,
              ),
            pathMatch: 'full',
          },
          {
            path: 'account',
            loadComponent: () =>
              import('./pages/customer/account/account.component').then(
                (m) => m.AccountComponent,
              ),
          },
          {
            path: 'account/edit',
            loadComponent: () =>
              import('./pages/customer/account/edit-account/edit-account.component').then(
                (m) => m.EditAccountComponent,
              ),
            pathMatch: 'full',
          },
          {
            path: 'orders',
            loadComponent: () =>
              import('./pages/customer/orders/orders.component').then(
                (m) => m.OrdersComponent,
              ),
          },
          {
            path: 'wishlist',
            loadComponent: () =>
              import('./pages/customer/wishlist/wishlist.component').then(
                (m) => m.WishlistComponent,
              ),
          },
        ],
      },

      {
        path: 'p/:productUrl',
        loadComponent: () =>
          import('./pages/product/product-page/product-page.component').then(
            (m) => m.ProductPageComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('checkout'),
        canActivate: [CheckoutGuard],
        runGuardsAndResolvers: 'always',
        loadComponent: () =>
          import('./pages/checkout-page/checkout-page.component').then(
            (m) => m.CheckoutPageComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('staticPages'),
        loadChildren: () =>
          import('./pages/static/static.routes').then((m) => m.staticRoutes),
      },
      {
        matcher: isTaxonPath,
        loadComponent: () =>
          import('./pages/product/product-list/product-list.component').then(
            (m) => m.ProductListComponent,
          ),
      },
      {
        path: 'order-status',
        loadComponent: () =>
          import('./pages/thank-you/thank-you.component').then(
            (m) => m.ThankYouComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('login'),
        canActivate: [isNotLoggedInGuard],
        loadComponent: () =>
          import('./pages/login/login.component').then((m) => m.LoginComponent),
      },
      {
        matcher: isTranslatedUrl('register'),
        canActivate: [isNotLoggedInGuard],
        loadComponent: () =>
          import('./pages/register/register.component').then((m) => m.RegisterComponent),
      },
      {
        matcher: isTranslatedUrl('yesCampaign'),
        loadComponent: () =>
          import('./pages/yes-campaign/yes-campaign.component').then(
            (m) => m.YesCampaignComponent,
          ),
      },
      {
        path: 'club',
        loadComponent: () =>
          import('./pages/club/club.component').then(
            (m) => m.ClubComponent,
          ),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
];

if (isDevMode()) {
  baseRoutes.unshift({
    path: 'design-system',
    loadChildren: () =>
      import('./pages/design-system/design-system.routes').then((m) => m.routes),
  });
}

export const routes = baseRoutes;
